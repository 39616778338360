import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header-menu",
  templateUrl: "./header-menu.page.html",
  styleUrls: ["./header-menu.page.scss"],
})
export class HeaderMenuPage implements OnInit {
  @Input() cycleModule: Boolean = false;
  @Input() agentName;
  homeClass = "inactive";
  policiesClass = "inactive";
  quotesClass = "inactive";
  enquiriesClass = "inactive";
  claimsClass = "inactive";
  tab;
  showList = false;
  token;
  url = environment.apiUrl;
  res;
  constructor(public http: HttpClient, public router: Router) {}
  onHamClick() {
    console.log("clicked ham");
    console.log(this.showList);

    this.showList = !this.showList;
    console.log(this.showList);
  }
  onClick(tab) {
    console.log(tab);
    this.tab = tab;
    this.anotherFunc();
  }
  anotherFunc() {
    if (this.tab == "home") {
      this.homeClass = "active";
      this.policiesClass = "inactive";
      this.quotesClass = "inactive";
      this.enquiriesClass = "inactive";
      this.claimsClass = "inactive";
    } else if (this.tab == "policies") {
      this.homeClass = "inactive";
      this.policiesClass = "active";
      this.quotesClass = "inactive";
      this.enquiriesClass = "inactive";
      this.claimsClass = "inactive";
    } else if (this.tab == "quotes") {
      this.homeClass = "inactive";
      this.policiesClass = "inactive";
      this.quotesClass = "active";
      this.enquiriesClass = "inactive";
      this.claimsClass = "inactive";
    } else if (this.tab == "enquiries") {
      this.homeClass = "inactive";
      this.policiesClass = "inactive";
      this.quotesClass = "inactive";
      this.enquiriesClass = "active";
      this.claimsClass = "inactive";
    } else if (this.tab == "claims") {
      this.homeClass = "inactive";
      this.policiesClass = "inactive";
      this.quotesClass = "inactive";
      this.enquiriesClass = "inactive";
      this.claimsClass = "active";
    }
  }
  ngOnInit() {
    console.log("head");
    this.token = window.localStorage.getItem("token");

    this.agentName = window.localStorage.getItem("agentName");
    // const headers = { 'Authorization':'Bearer '+this.token}
    // this.http.get(this.url+"api/v1/client/profile/dashboard",{ headers }).subscribe(data=>{
    //   this.res=data;
    //   this.agentName=this.res?.client.firstName
    //   console.log(this.res,'Header data');

    // },err=>{
    //   console.log(err);

    // })
  }

  onLogout() {
    window.localStorage.removeItem("token");
    this.router.navigate(["/home"]);
  }
}
