import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LoadingController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  loading;
  url=environment.apiUrl;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public http:HttpClient,
    public navCtrl: NavController,
    private storage: Storage,
    public loadingController: LoadingController,
    public router:Router
  ) {
    this.initializeApp();
  }
  

 async initializeApp() {
  // this.loading = await this.loadingController.create({
  //   cssClass: 'my-custom-class',
  //   message: 'Fetching user details',
  //   spinner: "dots",
  //   animated:true
  // });

  //  var token;
  //  var response;
  //  token=this.storage.get('token')
  //  token=window.localStorage.getItem("token");
      // if(true){
      //   if(token){
      //     console.log(token);
      //     this.loading.present();
      //     const headers = { 'Authorization':'Bearer '+token}
      //     this.http.get(this.url+"api/v1/client/profile/dashboard",{ headers }).subscribe(data=>{
      //       response = data;
      //       console.log(response)
      //     if(response.client.status == "verifiedd" || response.client.status == "submitted" || response.client.status == "rejected" || response.client.status == "cancelled"){
      //       this.loading.dismiss();
      //       this.navCtrl.navigateForward("/page7");
      //     }else if(response.client.status == "incomplete"){
      //       console.log("incomp");
      //         if(response.client.registerStep){
      //           console.log("step");
      //           console.log(response.client.registerStep);
      //           switch(response.client.registerStep){
      //               case 1:
      //               this.loading.dismiss();
      //               this.navCtrl.navigateForward("/page3");
      //               break;
      //               case 2:
      //               this.loading.dismiss();
      //               this.navCtrl.navigateForward("/page4");
      //               break;
      //               case 3:
      //                 this.loading.dismiss();
      //                 this.navCtrl.navigateForward("/page5");    
      //                 break;
      //                 default: return; 
      //               }
      //             }else{
      //               this.loading.dismiss();                    
      //               this.navCtrl.navigateForward("/page2");
      //         }
      //     }else if(response.client.status == "onboarded" || response.client.status == "verified"){
      //       this.loading.dismiss();
      //       this.navCtrl.navigateForward("/cycle-module");
      //     }
      //     },err=>{
      //       this.loading.dismiss();
      //       alert(err.error.error);
      //       this.navCtrl.navigateForward("/home")
      //     })
      //   }else{
      //     this.navCtrl.navigateForward("/home")
      //   }
      // }
      
      if(this.router.url=="/"){
        console.log(this.router.url,"urllll");
        // this.router.navigate(["/home"])
        // this.navCtrl.navigateForward("/home");
      }
      this.platform.ready().then(() => {
      this.statusBar.styleDefault();
       

    });
  }
}
