import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ApiServiceService } from '../api-service.service';

@Component({
  selector: 'app-claim-popup',
  templateUrl: './claim-popup.component.html',
  styleUrls: ['./claim-popup.component.scss'],
})
export class ClaimPopupComponent implements OnInit {
  @Input() policyId;
  token;
  url=environment.cycleApiUrl
  body={
    file:[]
  }
  proof;
  proofRes;
  finalData;
  constructor(public modalcontroller:ModalController,
    public apiservice:ApiServiceService,
    public http:HttpClient) { }
  async close(){
    await this.modalcontroller.dismiss();
  }

  ngOnInit() {
    this.token=window.localStorage.getItem('token');
    this.body['policyId']=this.policyId
  }
  addClaim(){
    console.log(this.body);
    
    const headers = { 'Authorization':'Bearer '+this.token}
    this.http.post(this.url+'claim',this.body,{headers}).subscribe(data=>{
      console.log(data);
      
    },err=>{
      console.log(err);
      
    })
  }
  _base64ToArrayBuffer(imageData) {
    var binary_string =  atob(imageData);
    var len = binary_string.length;
    var bytes = new Uint8Array( len );
    for (var i = 0; i < len; i++)        {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  uploadProof(e){
    let file=e.target.files[0]
    const headers = { 'Authorization':'Bearer '+this.token}
    this.http.get(this.url+'policy/presignedurl?key=bicycle-claim',{headers}).subscribe(data=>{
      this.proofRes=data;
      console.log(this.proofRes);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.proof=reader.result;
          this.finalData=reader.result.slice(23);
          console.log(this.finalData,'fial');
          let realFile=this._base64ToArrayBuffer(this.finalData);
          console.log(realFile,"real");
          
          const headers = {'Content-Type': 'image/jpeg'}
          this.http.put(this.proofRes.result[0].url,realFile,{headers}).subscribe(data=>{
            console.log(data,'afer');
            this.body.file.push(this.proofRes.result[0].Key)
            console.log(this.body);
            
          })
        };
      },err=>{
        console.log(err);
        
      })
      
      
  }
}
