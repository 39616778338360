import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { LoadingController, NavController } from "@ionic/angular";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HomepgGuard implements CanActivate {
  loading;
  url = environment.apiUrl;
  constructor(
    public router: Router,
    public loadingController: LoadingController,
    public navCtrl: NavController,
    public http: HttpClient
  ) {}
  canActivate(): boolean {
    let token = window.localStorage.getItem("token");
    console.log(token, "tookeenn");
    if (token == undefined || token == null) {
      console.log("home");

      return true;
    } else {
      this.checkToken();
      return false;
    }
  }
  checkToken() {
    console.log("checking token");

    let token = window.localStorage.getItem("token");
    var response;
    const headers = { Authorization: "Bearer " + token };
    this.http
      .get(this.url + "api/v1/client/profile/dashboard", { headers })
      .subscribe(
        (data) => {
          response = data;
          console.log(response);
          if (
            response.client.status == "verifiedd" ||
            response.client.status == "submitted" ||
            response.client.status == "rejected" ||
            response.client.status == "cancelled"
          ) {
            this.navCtrl.navigateForward("/page7");
            return false;
          } else if (response.client.status == "incomplete") {
            console.log("incomp");
            if (response.client.registerStep) {
              console.log("step");
              console.log(response.client.registerStep);
              switch (response.client.registerStep) {
                case 1:
                  this.navCtrl.navigateForward("/page3");
                  break;
                case 2:
                  this.navCtrl.navigateForward("/page4");
                  break;
                case 3:
                  this.navCtrl.navigateForward("/page5");
                  break;
                default:
                  return;
              }
            } else {
              this.navCtrl.navigateForward("/page2");
              return false;
            }
          } else if (
            response.client.status == "onboarded" ||
            response.client.status == "verified"
          ) {
            this.navCtrl.navigateForward("/dashboard");
            return false;
          }
        },
        (err) => {
          // alert(err.error.error);
          this.navCtrl.navigateForward("/home");
          return false;
        }
      );
  }
}
