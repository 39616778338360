import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-renew-policy-popup',
  templateUrl: './renew-policy-popup.component.html',
  styleUrls: ['./renew-policy-popup.component.scss'],
})
export class RenewPolicyPopupComponent implements OnInit {

  constructor(public router: Router,public modalController: ModalController) { }
  onChange(e){
    if(e.target.value=='yes'){
      this.modalController.dismiss();
      this.router.navigate(['listings-page']);
    }else{
      this.modalController.dismiss();
      this.router.navigate(['quotes-page']);
    }
  }
  ngOnInit() {}

}
