import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ApiServiceService } from '../api-service.service';

@Component({
  selector: 'app-choose-prev-policytype',
  templateUrl: './choose-prev-policytype.component.html',
  styleUrls: ['./choose-prev-policytype.component.scss'],
})
export class ChoosePrevPolicytypeComponent implements OnInit {
  ownDamageOnly=false;
  tpNumber='hide'
  allData;
  apiResponse;
  newApiUrl=environment.newApiUrl;
  isBrandNew;
  productCode;
  getQuoteSpinner=false;
  constructor(public router:Router,
    public modalcontroller:ModalController,
    public apiservice:ApiServiceService,
    public http:HttpClient) { }


  ngOnInit() {
    this.isBrandNew=window.localStorage.getItem('isBrandNew');
    this.apiservice.sharedParam.subscribe(param=>this.allData=param);
    this.productCode=this.allData.productCode;
  }
  onGetQuote(){
    this.getQuoteSpinner=true;
    console.log("clicked getquote");
    if(this.productCode=="rollover"){
      this.allData['ncbcurrent']="0"
      this.allData['claimAmountReceived']="0"
      this.allData['claimsReported']="0"
      this.allData['ncbprevious']="0"
      this.allData['vechileOwnerShipChanged']="No"
      this.allData['idvFor2Year']="0"
      this.allData['discountIDVPercent2Year']="0"
      this.allData['modifiedIDVfor2Year']="0"
      this.allData['idvFor3Year']="0"
      this.allData['discountIDVPercent3Year']="0"
      this.allData['modifiedIDVfor3Year']="0"
      this.allData['cpaPolicyTerm']="0"
      this.allData['previousPolicyNo']="DSJ00000111"
      this.allData['previousInsurerCode']="null"
    }else if(this.productCode=="od"){
      this.allData['tpInceptionDate']="03/03/2020"
      this.allData['tpExpiryDate']="02/03/2024"
      this.allData['previousPolicyNo']="DSJ00000111"
      this.allData['ncbcurrent']="0"
      this.allData['claimAmountReceived']="0"
    }
    console.log(this.allData , "sent Final Data");
    
    this.http.post(this.newApiUrl+'quote/quickquote',this.allData).subscribe(data=>{
      console.log(data);
      
      this.apiResponse=data;
      this.apiservice.changeapiResponse(this.apiResponse);
      this.modalcontroller.dismiss();
      this.router.navigate(["listings-page"]);
      this.getQuoteSpinner=false;
    },err=>{
      alert(err.error.message)
      this.getQuoteSpinner=false;
    })
    
  }

  onChoose(e){
    this.allData['previousPolicyType']=e.target.value
    if(e.target.value=="Own damage"){
      this.ownDamageOnly=true;
      this.tpNumber='show';
    }else{
      this.ownDamageOnly=false;
      this.tpNumber='hide';
    }
  }

}
