import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guard/auth.guard";
import { HomepgGuard } from "./guard/homepg.guard";
import { TokenAuthGuard } from "./guard/token-auth.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "home",
    loadChildren: () =>
      import("./home/home.module").then((m) => m.HomePageModule),
    canActivate: [HomepgGuard],
  },
  {
    path: "page1",
    loadChildren: () =>
      import("./page1/page1.module").then((m) => m.Page1PageModule),
    canActivate: [HomepgGuard],
  },
  {
    path: "page2",
    loadChildren: () =>
      import("./page2/page2.module").then((m) => m.Page2PageModule),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "page3",
    loadChildren: () =>
      import("./page3/page3.module").then((m) => m.Page3PageModule),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "page4",
    loadChildren: () =>
      import("./page4/page4.module").then((m) => m.Page4PageModule),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "page5",
    loadChildren: () =>
      import("./page5/page5.module").then((m) => m.Page5PageModule),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "page6",
    loadChildren: () =>
      import("./page6/page6.module").then((m) => m.Page6PageModule),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "page7",
    loadChildren: () =>
      import("./page7/page7.module").then((m) => m.Page7PageModule),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "page8",
    loadChildren: () =>
      import("./page8/page8.module").then((m) => m.Page8PageModule),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "page9",
    loadChildren: () =>
      import("./page9/page9.module").then((m) => m.Page9PageModule),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "bike-module",
    loadChildren: () =>
      import("./bike-module/bike-module.module").then(
        (m) => m.BikeModulePageModule
      ),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "listings-page",
    loadChildren: () =>
      import("./listings-page/listings-page.module").then(
        (m) => m.ListingsPagePageModule
      ),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "profile-page",
    loadChildren: () =>
      import("./profile-page/profile-page.module").then(
        (m) => m.ProfilePagePageModule
      ),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "details-collection",
    loadChildren: () =>
      import("./details-collection/details-collection.module").then(
        (m) => m.DetailsCollectionPageModule
      ),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardPageModule),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "policies-sold",
    loadChildren: () =>
      import("./policies-sold/policies-sold.module").then(
        (m) => m.PoliciesSoldPageModule
      ),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "claims-listing",
    loadChildren: () =>
      import("./claims-listing/claims-listing.module").then(
        (m) => m.ClaimsListingPageModule
      ),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "enquiries-listing",
    loadChildren: () =>
      import("./enquiries-listing/enquiries-listing.module").then(
        (m) => m.EnquiriesListingPageModule
      ),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "header-menu",
    loadChildren: () =>
      import("./header-menu/header-menu.module").then(
        (m) => m.HeaderMenuPageModule
      ),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "single-policy-details",
    loadChildren: () =>
      import("./single-policy-details/single-policy-deatils.module").then(
        (m) => m.SinglePolicyDeatilsPageModule
      ),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "quotes-page",
    loadChildren: () =>
      import("./quotes-page/quotes-page.module").then(
        (m) => m.QuotesPagePageModule
      ),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "reminders-page",
    loadChildren: () =>
      import("./reminders-page/reminders-page.module").then(
        (m) => m.RemindersPagePageModule
      ),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "mobile-listing-filter",
    loadChildren: () =>
      import("./mobile-listing-filter/mobile-listing-filter.module").then(
        (m) => m.MobileListingFilterPageModule
      ),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "edit-vehicle-details",
    loadChildren: () =>
      import("./edit-vehicle-details/edit-vehicle-details.module").then(
        (m) => m.EditVehicleDetailsPageModule
      ),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "mobile-notifications",
    loadChildren: () =>
      import("./mobile-notifications/mobile-notifications.module").then(
        (m) => m.MobileNotificationsPageModule
      ),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "pg-failure",
    loadChildren: () =>
      import("./pg-failure/pg-failure.module").then(
        (m) => m.PgFailurePageModule
      ),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "pg-success",
    loadChildren: () =>
      import("./pg-success/pg-success.module").then(
        (m) => m.PgSuccessPageModule
      ),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "bike-loading",
    loadChildren: () =>
      import("./bike-loading/bike-loading.module").then(
        (m) => m.BikeLoadingPageModule
      ),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "cycle-module",
    loadChildren: () =>
      import("./cycle-module/cycle-module.module").then(
        (m) => m.CycleModulePageModule
      ),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "claim-popup-filled",
    loadChildren: () =>
      import("./claim-popup-filled/claim-popup-filled.module").then(
        (m) => m.ClaimPopupFilledPageModule
      ),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "claim-popup-new",
    loadChildren: () =>
      import("./claim-popup-new/claim-popup-new.module").then(
        (m) => m.ClaimPopupNewPageModule
      ),
    canActivate: [TokenAuthGuard],
  },
  {
    path: "cycle-premium-popup",
    loadChildren: () =>
      import("./cycle-premium-popup/cycle-premium-popup.module").then(
        (m) => m.CyclePremiumPopupPageModule
      ),
  },
  {
    path: "health-module",
    loadChildren: () =>
      import("./health-module/health-module.module").then(
        (m) => m.HealthModulePageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
