import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  private paramSource = new BehaviorSubject(null);
  sharedParam = this.paramSource.asObservable();

  private apiResponseSource= new BehaviorSubject(null);
  apiResponse =this.apiResponseSource.asObservable();


  changeParam(param: any) {
    this.paramSource.next(param)
  }
  changeapiResponse(param: any) {
    this.apiResponseSource.next(param)
  }
  constructor() { }
}
