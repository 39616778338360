import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Camera } from '@ionic-native/camera/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule } from '@angular/common/http';
import { ChartsModule } from 'ng2-charts';
import { HeaderMenuPageModule } from './header-menu/header-menu.module';
import { ClaimPopupComponent } from './claim-popup/claim-popup.component';
import { AddEnquiryComponent } from './add-enquiry/add-enquiry.component';
import { AddPolicyComponent } from './add-policy/add-policy.component';
import { ChoosePrevPolicytypeComponent } from './choose-prev-policytype/choose-prev-policytype.component';
import { PremiumDetailsComponent } from './premium-details/premium-details.component';
import { RsSubmitPopupComponent } from './rs-submit-popup/rs-submit-popup.component';
import { HdfcPgPopupComponent } from './hdfc-pg-popup/hdfc-pg-popup.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { FilterPopupComponent } from './filter-popup/filter-popup.component';
import { RenewPolicyPopupComponent } from './renew-policy-popup/renew-policy-popup.component';
import { CyclePremiumPopupPage } from './cycle-premium-popup/cycle-premium-popup.page';
export function playerFactory(){
  return player
}
@NgModule({
  declarations: [AppComponent,ClaimPopupComponent,AddEnquiryComponent,AddPolicyComponent,ChoosePrevPolicytypeComponent,PremiumDetailsComponent,RsSubmitPopupComponent,HdfcPgPopupComponent,FilterPopupComponent,ClaimPopupComponent,RenewPolicyPopupComponent],
  entryComponents: [],
  imports: [BrowserModule,HttpClientModule,HeaderMenuPageModule,IonicModule, IonicModule.forRoot({animated:false}), AppRoutingModule, IonicStorageModule.forRoot(),ChartsModule,
  LottieModule.forRoot({player:playerFactory})],
  providers: [
    StatusBar,
    SplashScreen,
    Camera,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
