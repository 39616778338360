import { Component, OnInit,Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-premium-details',
  templateUrl: './premium-details.component.html',
  styleUrls: ['./premium-details.component.scss'],
})
export class PremiumDetailsComponent implements OnInit {
  @Input() netPremium: any;
  @Input() companyName: string;
  @Input() grossPremium: string;
  @Input() basicLp;
  @Input() basicOp;
  addOns;
  gst;
  roundedGst;
  constructor(public router:Router,
    public modalcontroller:ModalController) { }

  ngOnInit() {
    console.log(this.basicLp);
    
    if(this.basicLp==(undefined || null)){
      this.basicLp=0;
    }
    if(this.basicOp==(undefined || null)){
      this.basicOp=0;
    }
    this.gst=(this.netPremium*18)*0.01;
    this.roundedGst = Math.round(this.gst * 10) / 10
    this.addOns=this.netPremium-(this.basicLp+this.basicOp);
    console.log("cname",window.localStorage.getItem("sCompanyName"));
    console.log("qid",window.localStorage.getItem("qid"));
    
  }
  close(){
    this.modalcontroller.dismiss();
  }
  onProceed(){
    this.modalcontroller.dismiss();
    this.router.navigate(["details-collection"]);
  }
}
