import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.scss'],
})
export class FilterPopupComponent implements OnInit {

  constructor(public modalcontroller:ModalController) { }
  async close(){
    await this.modalcontroller.dismiss();
  }

  ngOnInit() {}

}
