import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenAuthGuard implements CanActivate {
  constructor(public router:Router){

  }
  
  canActivate(): boolean{
    let token=window.localStorage.getItem('token');
    if(token!=undefined || token!=null){
      return true
    }else{
      this.router.navigate(['home']);
      return false
    }
  }
  
}
