import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hdfc-pg-popup',
  templateUrl: './hdfc-pg-popup.component.html',
  styleUrls: ['./hdfc-pg-popup.component.scss'],
})
export class HdfcPgPopupComponent implements OnInit {
  @Input() Trnsno;
  @Input() Chksum;
  @Input() amt;
  constructor() { }

  ngOnInit() {}

}
