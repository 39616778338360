import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-rs-submit-popup',
  templateUrl: './rs-submit-popup.component.html',
  styleUrls: ['./rs-submit-popup.component.scss'],
})
export class RsSubmitPopupComponent implements OnInit {
  @Input() premium: string;
  @Input() qid: string;
  constructor() { }

  ngOnInit() {}

}
